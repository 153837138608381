import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { builder, BuilderComponent } from '@builder.io/react';
import lodash from 'lodash';
import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';
import { mixpanel } from '@fiverr-private/obs';
import { raise, raiseToast } from '@fiverr-private/toast';
import { getContext } from '@fiverr-private/fiverr_context';
import { Center } from '@fiverr-private/layout_components';
import { Loader } from '@fiverr-private/feedback';
import EventReportingManager from '../EventReportingManager';
import registerComponents from '../../../server/utils/register_components';
import Logger from '../../../server/lib/logger';
import { convertContextToMixpanel } from '../EventReportingManager/utils';
import { EditorAsyncPropsContextProvider } from '../EditorAsyncProps';
import { getConvertPriceFromUSD, numberFormat, useBuilderTimeoutRetry } from './utils';
import { editorCss } from './constants';

const BuilderRenderer = ({
    pageContent,
    builderModel,
    apiKey,
    contextData = {},
    isEditMode,
    logContext,
    reportingContext,
    graphqlResponse = {},
    dataModels = {},
    componentsAsyncProps = {},
    isBuilderTimeoutError = false,
}) => {
    Logger.init(logContext);
    builder.init(apiKey);
    registerComponents(isEditMode, pageContent?.meta?.componentsUsed);

    const { userId } = getContext();

    useEffect(() => {
        if (!isEditMode) {
            mixpanel.trackPageView({
                'Page Name': reportingContext?.page?.name,
                'Page Type': 'Content Page',
                ...(userId && { 'User Id': userId }),
                ...convertContextToMixpanel(pageContent?.data?.mixpanelPageViewEnrichment),
            });
        }
    }, [reportingContext, isEditMode, userId, pageContent]);

    const builderComponentProps = {
        locale: contextData.locale,
        data: { contextData, graphqlResponse, dataModels },
        context: {
            lodash,
            axios,
            pathfinder,
            logger: Logger.getInstance(),
            numberFormat,
            convertPriceFromUSD: getConvertPriceFromUSD(contextData),
            raise,
            raiseToast,
        },
        content: pageContent,
        model: builderModel,
    };

    useBuilderTimeoutRetry(isBuilderTimeoutError);

    return (
        <EditorAsyncPropsContextProvider value={{ isEditMode, componentsAsyncProps }}>
            <EventReportingManager context={reportingContext} reportingEnabled={!isEditMode}>
                {!isBuilderTimeoutError ? (
                    <>
                        {isEditMode && <style>{editorCss}</style>}
                        <BuilderComponent {...builderComponentProps} />
                    </>
                ) : (
                    <Center paddingY="32">
                        <Loader size="lg" />
                    </Center>
                )}
            </EventReportingManager>
        </EditorAsyncPropsContextProvider>
    );
};

BuilderRenderer.propTypes = {
    pageContent: PropTypes.object,
    contextData: PropTypes.object,
    reportingContext: PropTypes.object,
    logContext: PropTypes.object,
    builderModel: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    isEditMode: PropTypes.bool,
    graphqlResponse: PropTypes.string,
    dataModels: PropTypes.object,
    componentsAsyncProps: PropTypes.object,
    isBuilderTimeoutError: PropTypes.bool,
};

export default BuilderRenderer;
