import {
    Center,
    AspectRatio,
    Grid,
    GridItem,
    Stack,
    Wrap,
    WrapItem,
    Section,
    Divider,
    Container,
    LinkCard,
} from '@fiverr-private/layout_components';
import withImpression from '../helpers/withImpression';
import withClick from '../helpers/withClick';
import {
    impressionEventNameInput,
    componentNameInput,
    eventEnrichmentInput,
    clickEventNameInput,
} from '../helpers/inputs';
import CenterConfig from '../../../../../../docs/builderLayoutComponents/Center.json';
import AspectRatioConfig from '../../../../../../docs/builderLayoutComponents/AspectRatio.json';
import SectionConfig from '../../../../../../docs/builderLayoutComponents/Section.json';
import ContainerConfig from '../../../../../../docs/builderLayoutComponents/Container.json';
import GridConfig from '../../../../../../docs/builderLayoutComponents/Grid.json';
import StackConfig from '../../../../../../docs/builderLayoutComponents/Stack.json';
import WrapConfig from '../../../../../../docs/builderLayoutComponents/Wrap.json';
import GridItemConfig from '../../../../../../docs/builderLayoutComponents/GridItem.json';
import WrapItemConfig from '../../../../../../docs/builderLayoutComponents/WrapItem.json';
import DividerConfig from '../../../../../../docs/builderLayoutComponents/Divider.json';
import LinkCardConfig from '../../../../../../docs/builderLayoutComponents/LinkCard.json';

const LAYOUT_COMPONENTS_CONFIG = {
    components: [
        {
            element: Center,
            componentConfig: CenterConfig,
            hoc: withImpression,
            manualInputs: [impressionEventNameInput],
        },
        {
            element: AspectRatio,
            componentConfig: AspectRatioConfig,
            hoc: withImpression,
            manualInputs: [impressionEventNameInput],
        },
        {
            element: Section,
            componentConfig: SectionConfig,
            hoc: withImpression,
            manualInputs: [impressionEventNameInput],
        },
        { element: Grid, componentConfig: GridConfig, hoc: withImpression, manualInputs: [impressionEventNameInput] },
        { element: Stack, componentConfig: StackConfig, hoc: withImpression, manualInputs: [impressionEventNameInput] },
        { element: Wrap, componentConfig: WrapConfig, hoc: withImpression, manualInputs: [impressionEventNameInput] },
        {
            element: GridItem,
            componentConfig: GridItemConfig,
            hoc: withImpression,
            manualInputs: [impressionEventNameInput],
        },
        {
            element: WrapItem,
            componentConfig: WrapItemConfig,
            hoc: withImpression,
            manualInputs: [impressionEventNameInput],
        },
        {
            element: Divider,
            componentConfig: DividerConfig,
            hoc: withImpression,
            manualInputs: [impressionEventNameInput],
        },
        {
            element: Container,
            componentConfig: ContainerConfig,
            hoc: withImpression,
            manualInputs: [impressionEventNameInput],
        },
        {
            element: LinkCard,
            componentConfig: LinkCardConfig,
            hoc: withClick,
            manualInputs: [clickEventNameInput],
        },
    ],
    category: 'Layout',
    manualInputs: [componentNameInput, eventEnrichmentInput],
};

export default LAYOUT_COMPONENTS_CONFIG;
