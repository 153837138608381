import { Image } from '@builder.io/react';
import { COMPONENT_PREFIX } from '../../../constants';

const CLOUDINARY_IMAGE_CONFIG = {
    components: [
        {
            component: Image,
            options: {
                noWrap: false,
                name: `${COMPONENT_PREFIX}Cloudinary Image`,
                inputs: [{ name: 'image', type: 'cloudinaryImageEditor' }],
            },
        },
    ],
};

export default CLOUDINARY_IMAGE_CONFIG;
