const BUILDER_FORM_MENU = {
    name: 'Builder Forms',
    items: [
        { name: 'Core:Button' },
        { name: 'Form:Form' },
        { name: 'Form:Input' },
        { name: 'Form:Label' },
        { name: 'Form:Select' },
        { name: 'Form:TextArea' },
    ],
};

export default BUILDER_FORM_MENU;
