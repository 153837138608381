import { loadable } from '@fiverr-private/perseus';
import TooltipConfig from '../../../../../../docs/builderTooltipComponents/Tooltip.json';

const Tooltip = loadable(() => import(/* webpackChunkName: "Tooltip" */ './tooltip'));
Tooltip.displayName = 'Tooltip';

const DISCLOSURE_COMPONENTS_CONFIG = {
    components: [
        {
            element: Tooltip,
            componentConfig: TooltipConfig,
        },
    ],
    category: 'Tooltip',
};

export default DISCLOSURE_COMPONENTS_CONFIG;
