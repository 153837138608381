import { COMPONENT_PREFIX, OBJECT_FIELDS_VARIANTS } from '../../../src/server/constants';

export const COMPONENTS_TO_REGISTER = {
    THEME: [{ name: 'Box' }],
    FIT: [{ name: 'Avatar' }, { name: 'Button' }, { name: 'Tag' }],
    LAYOUT: [
        { name: 'AspectRatio' },
        { name: 'Center' },
        { name: 'Section' },
        { name: 'Container' },
        { name: 'Grid' },
        { name: 'GridItem' },
        { name: 'Stack' },
        { name: 'Wrap' },
        { name: 'WrapItem' },
        { name: 'Divider' },
        { name: 'LinkCard' },
    ],
    TYPOGRAPHY: [{ name: 'Text' }, { name: 'Heading' }, { name: 'Link' }, { name: 'Typography' }],
    DISCLOSURE: [
        { name: 'Collapsible' },
        { name: 'CollapsibleGroup' },
        { name: 'CollapsibleHeader' },
        { name: 'CollapsibleContent' },
    ],
    MEDIA: [{ name: 'Video' }],
    OVERLAY: [
        { name: 'Modal' },
        { name: 'ModalTrigger' },
        { name: 'ModalContent' },
        { name: 'ModalHeader' },
        { name: 'ModalBody' },
        { name: 'ModalFooter' },
        { name: 'ModalCancelBtn' },
        { name: 'ModalLoader' },
        { name: 'Drawer' },
        { name: 'DrawerTrigger' },
        { name: 'DrawerContent' },
        { name: 'DrawerHeader' },
        { name: 'DrawerBody' },
        { name: 'DrawerFooter' },
    ],
    TOOLTIP: [{ name: 'Tooltip', localizedInputs: ['content'] }],
    POPOVER: [{ name: 'Popover' }, { name: 'PopoverHeader' }, { name: 'PopoverContent' }, { name: 'PopoverAnchor' }],
    DROPDOWN: [
        { name: 'Dropdown' },
        { name: 'DropdownAnchor' },
        { name: 'DropdownContent' },
        { name: 'DropdownTrigger' },
        { name: 'DropdownMenu' },
        { name: 'MenuItem' },
        { name: 'MenuGroup' },
        { name: 'MenuDivider' },
    ],
    BUTTON: [{ name: 'Button' }, { name: 'IconButton' }, { name: 'CloseButton' }],
    LIST: [
        { name: 'List' },
        { name: 'UnorderedList' },
        { name: 'NumberedList' },
        { name: 'ListItem' },
        { name: 'ListItemIcon' },
    ],
    NAVIGATION: [
        { name: 'TabGroup' },
        { name: 'TabList' },
        { name: 'Tab' },
        { name: 'TabPanels' },
        { name: 'TabPanel' },
    ],
    FORMS: [
        { name: 'Control' },
        { name: 'FormControl' },
        { name: 'FormCounter' },
        { name: 'FormElement' },
        { name: 'FormHeader' },
        { name: 'FormHelperText' },
        { name: 'Form' },
        { name: 'Select' },
        { name: 'Option' },
    ],
    INPUTS: [
        { name: 'InputGroup' },
        { name: 'Input' },
        { name: 'InputPrefix' },
        { name: 'InputSuffix' },
        { name: 'ChoiceGroup' },
        { name: 'ChoiceGroupItem' },
        { name: 'CheckboxGroup' },
        { name: 'Checkbox' },
        { name: 'RadioGroup' },
        { name: 'Radio' },
        { name: 'Switch' },
        { name: 'Textarea' },
    ],
    CAROUSEL: [{ name: 'Carousel' }, { name: 'Arrows' }, { name: 'Slide' }, { name: 'Track' }],
    DATA_DISPLAY: [
        { name: 'Tag' },
        { name: 'TagPrefix' },
        { name: 'TagCloseIcon' },
        { name: 'TagImage' },
        { name: 'Rating' },
    ],
};

export const PROP_TYPES = {
    enum: 'enum',
    union: 'union',
    shape: 'shape',
    arrayOf: 'arrayOf',
    bool: 'bool',
    node: 'node',
    string: 'string',
    number: 'number',
    element: 'element',
    any: 'any',
};

const TS_TYPES = {
    reactNode: 'ReactNode',
    reactNodeOrArray: 'ReactNode | ReactNode[]',
    stringOrNumber: 'string | number',
    stringOrBoolean: 'string | boolean',
    anchorTarget: 'HTMLAttributeAnchorTarget',
    boolean: 'boolean',
    color: 'Color',
    stringOrMessage: 'ReactNode | { message: ReactNode; }',
    inputValue: 'string | number | readonly string[]',
};

export const BUILDER_PROP_TYPES = {
    enum: 'enum',
    boolean: 'boolean',
    number: 'number',
    string: 'string',
    list: 'list',
    object: 'object',
    color: 'color',
};

export const IGNORE_DESCRIPTION = '@ignore';
export const CHILDREN_PROP_NAME = 'children';
export const VALUE_PROP_NAME = 'value';
export const CUSTOM_PROP = 'custom';
export const BUILDER_COMPONENT_TYPE = '@type';
export const BUILDER_ELEMENT = '@builder.io/sdk:Element';
export const DEFAULT_TEXT_COMPONENT = {
    name: `${COMPONENT_PREFIX}Text`,
    options: { text: 'I am child text block!', color: { default: 'inherit' } },
};

export const PROP_TYPES_WITH_SUB_FIELDS = [BUILDER_PROP_TYPES.list, BUILDER_PROP_TYPES.object];

export const CONVERT_PROP_TYPES_MAP = {
    [PROP_TYPES.bool]: BUILDER_PROP_TYPES.boolean,
    [PROP_TYPES.enum]: BUILDER_PROP_TYPES.enum,
    [PROP_TYPES.string]: BUILDER_PROP_TYPES.string,
    [PROP_TYPES.number]: BUILDER_PROP_TYPES.number,
    [PROP_TYPES.node]: BUILDER_PROP_TYPES.string,
    [PROP_TYPES.element]: BUILDER_PROP_TYPES.string,
    [PROP_TYPES.any]: BUILDER_PROP_TYPES.string,
    [PROP_TYPES.arrayOf]: BUILDER_PROP_TYPES.list,
    [PROP_TYPES.shape]: BUILDER_PROP_TYPES.object,
    [TS_TYPES.reactNode]: BUILDER_PROP_TYPES.string,
    [TS_TYPES.reactNodeOrArray]: BUILDER_PROP_TYPES.string,
    [TS_TYPES.anchorTarget]: BUILDER_PROP_TYPES.string,
    [TS_TYPES.boolean]: BUILDER_PROP_TYPES.boolean,
    [TS_TYPES.stringOrNumber]: BUILDER_PROP_TYPES.string,
    [TS_TYPES.stringOrBoolean]: BUILDER_PROP_TYPES.string,
    [TS_TYPES.color]: BUILDER_PROP_TYPES.color,
    [TS_TYPES.stringOrMessage]: BUILDER_PROP_TYPES.string,
    [TS_TYPES.inputValue]: BUILDER_PROP_TYPES.string,
    [TS_TYPES.stringOrBoolean]: BUILDER_PROP_TYPES.string,
};

export const CONVERT_DEFAULT_VALUE = {
    null: null,
    true: true,
    false: false,
};

export const CONVERT_OBJECT_DEFAULT_VALUE = {
    '{}': {},
    '[]': [],
};

export const COMPUTED_DEFAULT_VALUES_TO_IGNORE = ['noop', 'undefined', 'Function.prototype'];

const getShapeValue = (keys, value) => {
    const res = {};
    keys.forEach((key) => {
        res[key] = value;
    });
    return res;
};

const getEnumValue = (keys) => {
    const res = [];
    keys.forEach((key) => {
        res.push({ value: key });
    });
    return res;
};

const HOVER_SELECTOR_TYPE = {
    type: CONVERT_PROP_TYPES_MAP[PROP_TYPES.shape],
    updatedType: {
        isInternal: true,
        name: PROP_TYPES.shape,
        value: getShapeValue(OBJECT_FIELDS_VARIANTS.HOVER_SELECTOR, {
            name: PROP_TYPES.string,
            required: false,
        }),
    },
};

export const DEFAULT_REFERENCES_CONVERT = {
    margin: ['margin', 'spacing'],
};

export const MANUAL_TYPES_CONVERT = {
    HTMLInputTypeAttribute: {
        type: CONVERT_PROP_TYPES_MAP[PROP_TYPES.enum],
        updatedType: {
            isInternal: true,
            name: PROP_TYPES.enum,
            value: getEnumValue([
                'text',
                'number',
                'date',
                'datetime-local',
                'file',
                'email',
                'hidden',
                'password',
                'submit',
                'tel',
                'time',
                'url',
            ]),
        },
    },
    HoverSelectorStringType: HOVER_SELECTOR_TYPE,
    Positions: {
        type: CONVERT_PROP_TYPES_MAP[PROP_TYPES.string],
        updatedType: {
            isInternal: true,
            name: PROP_TYPES.string,
        },
    },
    'Source[]': {
        type: CONVERT_PROP_TYPES_MAP[PROP_TYPES.arrayOf],
        updatedType: {
            isInternal: true,
            name: PROP_TYPES.shape,
            value: {
                src: {
                    name: PROP_TYPES.string,
                    required: true,
                },
                type: {
                    name: PROP_TYPES.string,
                    required: false,
                },
            },
        },
    },
    'Subtitle[]': {
        type: CONVERT_PROP_TYPES_MAP[PROP_TYPES.arrayOf],
        updatedType: {
            isInternal: true,
            name: PROP_TYPES.shape,
            value: getShapeValue(['src', 'srcLang', 'label'], {
                name: PROP_TYPES.string,
                required: false,
            }),
        },
    },
};
