import React, { createContext } from 'react';
import { node, object, boolean } from 'prop-types';
import { useImpressionManager } from '@fiverr-private/impressionable';
import ImpressionEventContainer from '../ImpressionEventContainer';

export const EventReportingManagerContext = createContext({
    context: {},
    enabled: false,
});

const WithImpressionManager = ({ children }) => {
    useImpressionManager();
    return <ImpressionEventContainer>{children}</ImpressionEventContainer>;
};
WithImpressionManager.propTypes = {
    children: node.isRequired,
};

const EventReportingManager = ({ context = {}, children, reportingEnabled }) => {
    const value = { context, enabled: reportingEnabled };

    if (reportingEnabled) {
        return (
            <EventReportingManagerContext.Provider value={value}>
                <WithImpressionManager>{children}</WithImpressionManager>
            </EventReportingManagerContext.Provider>
        );
    }

    return <EventReportingManagerContext.Provider value={value}>{children}</EventReportingManagerContext.Provider>;
};

EventReportingManager.propTypes = {
    children: node.isRequired,
    context: object,
    reportingEnabled: boolean,
};

export default EventReportingManager;
