import { loadable } from '@fiverr-private/perseus';
import { Button } from '@fiverr-private/button';
import withClick from '../helpers/withClick';
import { clickEventNameInput, componentNameInput, eventEnrichmentInput } from '../helpers/inputs';
import ButtonConfig from '../../../../../../docs/builderButtonComponents/Button.json';
import IconButtonConfig from '../../../../../../docs/builderButtonComponents/IconButton.json';
import CloseButtonConfig from '../../../../../../docs/builderButtonComponents/CloseButton.json';
import { VERSIONS } from '../../../../constants';
import {
    BUILDER_COMPONENT_TYPE,
    BUILDER_ELEMENT,
} from '../../../../../../bin/generate_docs/generate_builder_components/config';

const IconButton = loadable(() => import(/* webpackChunkName: "IconButton" */ './iconButton'));
IconButton.displayName = 'IconButton';

const CloseButton = loadable(() => import(/* webpackChunkName: "CloseButton" */ './closeButton'));
CloseButton.displayName = 'CloseButton';

const BUTTON_COMPONENTS_CONFIG = {
    components: [
        {
            element: withClick(Button),
            version: VERSIONS.V2,
            componentConfig: ButtonConfig,
        },
        {
            element: IconButton,
            componentConfig: IconButtonConfig,
            manualOptions: {
                defaultChildren: [
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        component: {
                            name: 'fiverr:Facebook icon',
                        },
                    },
                ],
            },
        },
        {
            element: CloseButton,
            componentConfig: CloseButtonConfig,
        },
    ],
    category: 'Buttons',
    manualInputs: [clickEventNameInput, componentNameInput, eventEnrichmentInput],
};

export default BUTTON_COMPONENTS_CONFIG;
