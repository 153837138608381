import { Builder, withChildren } from '@builder.io/react';
import { COMPONENT_PREFIX } from '../../constants';
import Logger from '../../lib/logger';
import AUTO_REGISTRATION_COMPONENTS_MAP from './auto_registration_components';
import MANUAL_REGISTRATION_COMPONENTS_LIST from './manual_registration_components';
import BUILDER_COMPONENTS_MENU from './builderComponentsMenu';
import BUILDER_FORM_MENU from './builderFormMenu';
import wrap from './wrap';
import calculateProps from './calculate_props';

const EDITOR_SETTINGS = 'editor.settings';
const INSERT_MENU = 'insertMenu';

const registerComponents = (isEditMode, componentsUsed = {}) => {
    const logger = Logger.getInstance();
    const insertMenuConfig = [];

    const getComponentName = (name, version = '') => `${version}${COMPONENT_PREFIX}${name}`;

    const filterComponents = (components, isBuilderComponents) =>
        isEditMode || isBuilderComponents
            ? components
            : components.filter(({ componentConfig, options, version }) =>
                  componentConfig
                      ? Boolean(componentsUsed[getComponentName(componentConfig.options.name, version)])
                      : Boolean(componentsUsed[options.name])
              );

    Object.keys(AUTO_REGISTRATION_COMPONENTS_MAP).forEach((type) => {
        const {
            components,
            category,
            hoc,
            manualInputs = [],
            manualOptions = {},
        } = AUTO_REGISTRATION_COMPONENTS_MAP[type];
        const menuItems = [];
        const usedComponents = filterComponents(components);
        usedComponents.forEach(
            ({
                element,
                componentConfig,
                hoc: componentHoc,
                manualInputs: componentManualInputs = [],
                manualOptions: componentManualOptions = {},
                version,
            }) => {
                try {
                    const { options, hasChildren } = componentConfig;

                    if (options) {
                        let inputs = [];
                        if (isEditMode) {
                            inputs = calculateProps([...componentManualInputs, ...manualInputs, ...options.inputs]);
                        }
                        const componentName = getComponentName(options.name, version);
                        let updatedComponent = componentHoc ? componentHoc(element) : element;
                        updatedComponent = hoc ? hoc(updatedComponent) : updatedComponent;
                        updatedComponent =
                            componentManualOptions.noWrap === false ? updatedComponent : wrap(updatedComponent);
                        Builder.registerComponent(hasChildren ? withChildren(updatedComponent) : updatedComponent, {
                            noWrap: true,
                            ...options,
                            ...manualOptions,
                            ...componentManualOptions,
                            inputs,
                            name: componentName,
                        });
                        if (!componentManualOptions.hideFromInsertMenu) {
                            menuItems.push({ name: componentName });
                        }
                    }
                } catch (e) {
                    logger.error(e, {
                        description: 'Failed to register component',
                        component: componentConfig.options.name,
                    });
                }
            }
        );
        if (isEditMode && category) {
            insertMenuConfig.push({
                name: category,
                items: menuItems,
            });
        }
    });

    Object.keys(MANUAL_REGISTRATION_COMPONENTS_LIST).forEach((type) => {
        const { components, category, isBuilderComponents } = MANUAL_REGISTRATION_COMPONENTS_LIST[type];
        const menuItems = [];
        const usedComponents = filterComponents(components, isBuilderComponents);
        usedComponents.forEach(({ component, options, hasChildren }) => {
            const getComponent = () => {
                if (isBuilderComponents) {
                    return component;
                }
                if (options?.noWrap === false) {
                    return hasChildren ? withChildren(component) : component;
                }
                return hasChildren ? withChildren(wrap(component)) : wrap(component);
            };

            Builder.registerComponent(getComponent(), {
                noWrap: true,
                ...options,
            });
            if (!options.hideFromInsertMenu) {
                menuItems.push({ name: options.name });
            }
        });

        if (isEditMode && category) {
            insertMenuConfig.push({
                name: category,
                items: menuItems,
            });
        }
    });

    if (isEditMode) {
        insertMenuConfig.push(BUILDER_COMPONENTS_MENU);
        insertMenuConfig.push(BUILDER_FORM_MENU);
        Builder.register(EDITOR_SETTINGS, { customInsertMenu: true });
        insertMenuConfig.forEach((config) => {
            Builder.register(INSERT_MENU, config);
        });
    }
};

export default registerComponents;
