const eventNameInput = {
    name: 'eventName',
    type: 'string',
    required: false,
};

export const impressionEventNameInput = {
    ...eventNameInput,
    friendlyName: 'Impression Event Name',
};

export const clickEventNameInput = {
    ...eventNameInput,
    friendlyName: 'Click Event Name',
};
export const componentNameInput = {
    name: 'reportingComponentName',
    type: 'string',
    required: false,
};

export const eventEnrichmentInput = {
    name: 'eventEnrichment',
    type: 'map',
    required: false,
};

export const withCloseButtonInput = {
    name: 'withCloseButton',
    type: 'boolean',
    required: false,
    defaultValue: true,
};

export const withChevronInput = {
    name: 'withChevron',
    type: 'boolean',
    required: false,
    defaultValue: true,
};

export const withSlidesIndicatorInput = {
    name: 'withSlidesIndicator',
    type: 'boolean',
    required: false,
    helperText: 'Works only when scrollType = "single" and only one slide is shown per breakpoint',
};
