import { useRef } from 'react';
import { useVideoBigQueryEvents, useVideoMixpanelEvents } from '@fiverr-private/media';
import { getContext } from '@fiverr-private/fiverr_context';
import { renderChildren } from '@fiverr-private/ui_utils';

const VideoEvent = ({ children, eventMetadata, autoPlay, subtitles = [] }) => {
    const { locale } = getContext();
    const videoRef = useRef(null);
    const hasSubtitles = subtitles.length > 0;

    const meta = {
        ...eventMetadata,
        autoPlay,
        subtitleLocale: hasSubtitles ? locale : null,
        subtitleExist: hasSubtitles,
    };
    useVideoMixpanelEvents(videoRef, meta);
    useVideoBigQueryEvents(videoRef, meta);

    return renderChildren(children, { ref: videoRef });
};

export default VideoEvent;
