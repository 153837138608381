const LISTINGS_COMMON_ENGINES = {
    search: 'search',
    categories: 'categories',
};

export const GIG_LISTINGS_ENGINES = {
    collections: 'collections',
    ...LISTINGS_COMMON_ENGINES,
};

export const EXPERT_LISTINGS_ENGINES = {
    ...LISTINGS_COMMON_ENGINES,
    seller_usernames: 'seller_usernames',
};
