import { loadable } from '@fiverr-private/perseus';
import { COMPONENT_PREFIX } from '../../../../constants';

const RecommendationsInspireCarousel = loadable(
    () => import(/* webpackChunkName: "RecommendationsInspireCarousel" */ './RecommendationsInspireCarousel')
);

const RECOMMENDATIONS_INSPIRE_CAROUSEL_CONFIG = {
    components: [
        {
            component: RecommendationsInspireCarousel,
            options: {
                name: `${COMPONENT_PREFIX}Recommendations Inspire Carousel`,
                inputs: [
                    {
                        name: 'relatedSubCategories',
                        type: 'list',
                        required: false,
                        defaultValue: [],
                        subFields: [
                            {
                                name: 'id',
                                type: 'number',
                                required: true,
                            },
                        ],
                    },
                    {
                        name: 'mixpanelPageName',
                        type: 'string',
                        required: true,
                        defaultValue: 'Builder.io Page',
                    },
                    {
                        name: 'mixpanelHostingScreen',
                        type: 'string',
                        required: true,
                        defaultValue: 'Builder.io Page',
                    },
                    {
                        name: 'mixpanelCarouselName',
                        type: 'string',
                        required: true,
                        defaultValue: 'Recommendations Inspire Carousel on Builder.io',
                    },
                ],
                noWrap: false,
            },
        },
    ],
};

export default RECOMMENDATIONS_INSPIRE_CAROUSEL_CONFIG;
