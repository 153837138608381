import FIT_COMPONENTS_CONFIG from './fit';
import LAYOUT_COMPONENTS_CONFIG from './layouts';
import TYPOGRAPHY_COMPONENTS_CONFIG from './typography';
import DISCLOSURE_COMPONENTS_CONFIG from './disclosure';
import MEDIA_COMPONENTS_CONFIG from './media';
import OVERLAY_COMPONENTS_CONFIG from './overlay';
import TOOLTIP_COMPONENTS_CONFIG from './tooltip';
import POPOVER_COMPONENTS_CONFIG from './popover';
import DROPDOWN_COMPONENTS_CONFIG from './dropdown';
import BUTTON_COMPONENTS_CONFIG from './button';
import NAVIGATION_COMPONENTS_CONFIG from './navigation';
import LIST_COMPONENTS_CONFIG from './list';
import INPUTS_COMPONENTS_CONFIG from './inputs';
import FORMS_COMPONENTS_CONFIG from './forms';
import CAROUSEL_COMPONENTS_CONFIG from './carousel';
import DATA_DISPLAY_COMPONENTS_CONFIG from './data_display';

const AUTO_REGISTRATION_COMPONENTS_MAP = {
    Fit: FIT_COMPONENTS_CONFIG,
    Layout: LAYOUT_COMPONENTS_CONFIG,
    Typography: TYPOGRAPHY_COMPONENTS_CONFIG,
    Disclosure: DISCLOSURE_COMPONENTS_CONFIG,
    Media: MEDIA_COMPONENTS_CONFIG,
    Overlay: OVERLAY_COMPONENTS_CONFIG,
    Tooltip: TOOLTIP_COMPONENTS_CONFIG,
    Popover: POPOVER_COMPONENTS_CONFIG,
    Dropdown: DROPDOWN_COMPONENTS_CONFIG,
    Button: BUTTON_COMPONENTS_CONFIG,
    Navigation: NAVIGATION_COMPONENTS_CONFIG,
    List: LIST_COMPONENTS_CONFIG,
    Inputs: INPUTS_COMPONENTS_CONFIG,
    Forms: FORMS_COMPONENTS_CONFIG,
    Carousel: CAROUSEL_COMPONENTS_CONFIG,
    Data_Display: DATA_DISPLAY_COMPONENTS_CONFIG,
};

export default AUTO_REGISTRATION_COMPONENTS_MAP;
