/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';

const INVALID_VALUES = ['auto', 'autoX', 'autoY'];

const INVALID_PROPS = {
    padding: INVALID_VALUES,
    paddingX: INVALID_VALUES,
    paddingY: INVALID_VALUES,
    paddingTop: INVALID_VALUES,
    paddingBottom: INVALID_VALUES,
    paddingLeft: INVALID_VALUES,
    paddingRight: INVALID_VALUES,
    gap: INVALID_VALUES,
    columnGap: INVALID_VALUES,
    rowGap: INVALID_VALUES,
};

const sanitizeProps = (props = {}) => {
    const res = { ...props };
    Object.keys(INVALID_PROPS).forEach((key) => {
        if (res[key]) {
            const propValue = res[key];
            const updatedValue = {};
            Object.keys(propValue).forEach((selectorKey) => {
                if (!INVALID_PROPS[key].includes(propValue[selectorKey])) {
                    updatedValue[selectorKey] = propValue[selectorKey];
                }
            });
            if (Object.keys(updatedValue).length > 0) {
                res[key] = updatedValue;
            } else {
                delete res[key];
            }
        }
    });

    return res;
};

const wrap = (WrappedComponent) => (props) => {
    const validProps = sanitizeProps(props);
    return (
        <WrappedComponent
            {...validProps.attributes}
            {...validProps}
            className={[validProps.className, validProps.attributes?.className].filter(Boolean).join(' ')}
            style={{ ...validProps.style, ...validProps.attributes.style }}
        />
    );
};

export default wrap;
