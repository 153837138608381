import { useRef, useEffect, useContext, useCallback } from 'react';
import { renderChildren } from '@fiverr-private/ui_utils';
import { EventReportingManagerContext } from '../EventReportingManager';
import { sendEvent } from '../EventReportingManager/utils';

const ClickEvent = ({ eventName, context = {}, children }) => {
    const { context: managerContext, enabled } = useContext(EventReportingManagerContext);
    const ref = useRef(null);
    const sendClickEvent = useCallback(() => {
        sendEvent(eventName, 'click', context, managerContext);
    }, [context, eventName, managerContext]);

    useEffect(() => {
        const element = ref?.current;
        if (element && enabled) {
            const eventType = element.tagName.toLowerCase() === 'a' ? 'mousedown' : 'click';

            element.addEventListener(eventType, sendClickEvent);
            return () => {
                if (element) {
                    element.removeEventListener(eventType, sendClickEvent);
                }
            };
        }
    }, [eventName, managerContext, sendClickEvent, enabled]);

    return renderChildren(children, { ref });
};

export default ClickEvent;
