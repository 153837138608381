import { loadable } from '@fiverr-private/perseus';
import FormConfig from '../../../../../../docs/builderFormComponents/Form.json';
import FormControlConfig from '../../../../../../docs/builderFormComponents/FormControl.json';
import ControlConfig from '../../../../../../docs/builderFormComponents/Control.json';
import FormCounterConfig from '../../../../../../docs/builderFormComponents/FormCounter.json';
import FormElementConfig from '../../../../../../docs/builderFormComponents/FormElement.json';
import FormHeaderConfig from '../../../../../../docs/builderFormComponents/FormHeader.json';
import FormHelperTextConfig from '../../../../../../docs/builderFormComponents/FormHelperText.json';

const Form = loadable(() => import(/* webpackChunkName: "Form" */ './form'));
Form.displayName = 'Form';

const FormControl = loadable(() => import(/* webpackChunkName: "Form" */ './formControl'));
FormControl.displayName = 'FormControl';

const Control = loadable(() => import(/* webpackChunkName: "Form" */ './control'));
Control.displayName = 'Control';

const FormHeader = loadable(() => import(/* webpackChunkName: "Form" */ './formHeader'));
Control.displayName = 'FormHeader';

const FormCounter = loadable(() => import(/* webpackChunkName: "Form" */ './formCounter'));
FormCounter.displayName = 'FormCounter';

const FormElement = loadable(() => import(/* webpackChunkName: "Form" */ './formElement'));
FormElement.displayName = 'FormElement';

const FormHelperText = loadable(() => import(/* webpackChunkName: "Form" */ './formHelperText'));
FormHelperText.displayName = 'FormHelperText';

const FORMS_COMPONENTS_CONFIG = {
    components: [
        { element: Form, componentConfig: FormConfig, manualOptions: { hideFromInsertMenu: true } },
        { element: FormControl, componentConfig: FormControlConfig, manualOptions: { hideFromInsertMenu: true } },
        { element: Control, componentConfig: ControlConfig, manualOptions: { noWrap: false } },
        {
            element: FormHeader,
            componentConfig: FormHeaderConfig,
            manualInputs: [
                {
                    name: 'label',
                    type: 'longText',
                    required: true,
                    defaultValue: 'This is text example',
                    localized: true,
                },
                {
                    name: 'subtitle',
                    type: 'longText',
                    required: false,
                    localized: true,
                },
                {
                    name: 'tooltip',
                    type: 'longText',
                    required: false,
                    localized: true,
                },
                {
                    name: 'isOptional',
                    type: 'boolean',
                    required: false,
                    defaultValue: false,
                },
            ],
        },
        { element: FormCounter, componentConfig: FormCounterConfig },
        { element: FormElement, componentConfig: FormElementConfig },
        { element: FormHelperText, componentConfig: FormHelperTextConfig },
    ],
    category: 'Forms',
};

export default FORMS_COMPONENTS_CONFIG;
