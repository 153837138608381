import { loadable } from '@fiverr-private/perseus';
import PopoverConfig from '../../../../../../docs/builderPopoverComponents/Popover.json';
import PopoverContentConfig from '../../../../../../docs/builderPopoverComponents/PopoverContent.json';
import PopoverAnchorConfig from '../../../../../../docs/builderPopoverComponents/PopoverAnchor.json';
import PopoverHeaderConfig from '../../../../../../docs/builderPopoverComponents/PopoverHeader.json';
import { withChevronInput, withCloseButtonInput } from '../helpers/inputs';
import {
    BUILDER_COMPONENT_TYPE,
    BUILDER_ELEMENT,
    DEFAULT_TEXT_COMPONENT,
} from '../../../../../../bin/generate_docs/generate_builder_components/config';

const Popover = loadable(() => import(/* webpackChunkName: "Popover" */ './popover'));
Popover.displayName = 'Popover';

const PopoverAnchor = loadable(() => import(/* webpackChunkName: "Popover" */ './popoverAnchor'));
PopoverAnchor.displayName = 'PopoverAnchor';

const PopoverContent = loadable(() => import(/* webpackChunkName: "Popover" */ './popoverContent'));
PopoverContent.displayName = 'PopoverContent';

const PopoverHeader = loadable(() => import(/* webpackChunkName: "Popover" */ './popoverHeader'));
PopoverHeader.displayName = 'PopoverHeader';

const POPOVER_COMPONENTS_CONFIG = {
    components: [
        {
            element: Popover,
            componentConfig: PopoverConfig,
            manualOptions: {
                defaultChildren: [
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: DEFAULT_TEXT_COMPONENT,
                            },
                        ],
                        component: {
                            name: 'fiverr:PopoverAnchor',
                        },
                    },
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: DEFAULT_TEXT_COMPONENT,
                            },
                        ],
                        component: {
                            name: 'fiverr:PopoverContent',
                            options: { withChevron: true },
                        },
                    },
                ],
            },
        },
        {
            element: PopoverAnchor,
            componentConfig: PopoverAnchorConfig,
        },
        {
            element: PopoverContent,
            componentConfig: PopoverContentConfig,
            manualInputs: [withChevronInput],
        },
        {
            element: PopoverHeader,
            componentConfig: PopoverHeaderConfig,
            manualInputs: [withCloseButtonInput],
        },
    ],
    category: 'Popover',
};

export default POPOVER_COMPONENTS_CONFIG;
