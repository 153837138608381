export const videoEventMetadata = {
    name: 'eventMetadata',
    type: 'object',
    required: false,
    subFields: [
        {
            name: 'pageName',
            type: 'string',
            required: false,
        },
        {
            name: 'videoName',
            type: 'string',
            required: false,
        },
    ],
};

export const source = {
    name: 'source',
    type: 'list',
    required: false,
    defaultValue: [],
    helperText: "This prop is deprecated. Use 'sources' instead.",
    subFields: [
        {
            name: 'src',
            type: 'string',
            required: true,
        },
        {
            name: 'type',
            type: 'string',
            required: false,
        },
    ],
};
