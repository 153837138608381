import { loadable } from '@fiverr-private/perseus';
import { CARD_VARIANT } from '@fiverr-private/listings/src/shared/constants';
import { LISTINGS_LAYOUT } from '@fiverr-private/listings/src/constants';
import { EXPERT_LISTINGS_ENGINES, GIG_LISTINGS_ENGINES } from './constants';

const GigCardListings = loadable(() => import(/* webpackChunkName: "gigCardListings" */ './components/GigListings'));

const GigCardCarousel = loadable(() => import(/* webpackChunkName: "gigCardCarousel" */ './components/GigCarousel'));

const SmallGigCardCarousel = loadable(() =>
    import(/* webpackChunkName: "smallGigCardCarousel" */ './components/SmallGigCarousel')
);

const ExpertCardCarousel = loadable(() =>
    import(/* webpackChunkName: "expertCardCarousel" */ './components/ExpertCardCarousel')
);

const ExpertCardListings = loadable(() =>
    import(/* webpackChunkName: "expertCardListings" */ './components/ExpertCardListings')
);

const promotedGigsInput = {
    name: 'number_of_promoted_gigs',
    type: 'number',
    showIf: `options.get('engine') === 'categories' || options.get('engine') === 'search'`,
};

const defaultOptions = {
    inputs: [
        { name: 'carousel_name', type: 'string', required: true },
        {
            name: 'user_name',
            type: 'string',
            showIf: `options.get('engine') === 'collections'`,
        },
        {
            name: 'filters',
            type: 'string',
            showIf: `options.get('engine') === 'categories' || options.get('engine') === 'search'`,
        },
        {
            name: 'query_string',
            type: 'string',
            showIf: `options.get('engine') === 'search'`,
        },
        {
            name: 'category_id',
            type: 'number',
            showIf: `options.get('engine') === 'categories'`,
        },
        {
            name: 'sub_category_id',
            type: 'number',
            showIf: `options.get('engine') === 'categories' || options.get('engine') === 'search'`,
        },
        {
            name: 'nested_sub_category_id',
            type: 'number',
            showIf: `options.get('engine') === 'categories' || options.get('engine') === 'search'`,
        },
        {
            name: 'collection_slug',
            type: 'string',
            helperText: 'Collection slug including an id number (if existing).',
            showIf: `options.get('engine') === 'collections'`,
        },
    ],
    defaults: {
        bindings: {
            'component.options.currencyObject': 'state.contextData.currencyObject',
        },
    },
};

const gigListingsInputs = [
    {
        name: 'engine',
        type: 'enum',
        defaultValue: 'collections',
        required: true,
        enum: Object.values(GIG_LISTINGS_ENGINES),
    },
    ...defaultOptions.inputs,
    {
        name: 'page_size',
        type: 'number',
        showIf: `options.get('engine') === 'categories' || options.get('engine') === 'search'`,
    },
    {
        name: 'fetchData',
        type: 'boolean',
        defaultValue: false,
        helperText: 'Set to true to fetch the gigs data.',
        onChange(options) {
            if (options.get('fetchData')) {
                setTimeout(() => {
                    options.set('fetchData', false);
                }, 1000);
            }
        },
    },
];

const expertListingsInputs = [
    {
        name: 'engine',
        type: 'enum',
        defaultValue: 'search',
        required: true,
        enum: Object.values(EXPERT_LISTINGS_ENGINES),
    },
    {
        name: 'userNames',
        type: 'text',
        helperText: 'String of seller usernames separated by comma. Example: username1,username2,username3',
        showIf: `options.get('engine') === 'seller_usernames'`,
    },
    ...defaultOptions.inputs,
    {
        name: 'cardVariant',
        type: 'enum',
        enum: Object.values(CARD_VARIANT),
        defaultValue: CARD_VARIANT.DEFAULT,
    },
    {
        name: 'showPrice',
        type: 'boolean',
        defaultValue: true,
    },
    {
        name: 'showHourlyPrice',
        type: 'boolean',
        defaultValue: false,
        showIf: `options.get('cardVariant') !== '${CARD_VARIANT.SERVICE}'`,
    },
];

const LISTINGS_COMPONENTS_CONFIG = {
    components: [
        {
            component: GigCardListings,
            options: {
                name: 'GigCardListings',
                ...defaultOptions,
                inputs: [...gigListingsInputs, promotedGigsInput],
                noWrap: false,
            },
        },
        {
            component: GigCardCarousel,
            options: {
                name: 'GigCardCarousel',
                ...defaultOptions,
                inputs: [...gigListingsInputs, promotedGigsInput],
                noWrap: false,
            },
        },
        {
            component: SmallGigCardCarousel,
            options: {
                name: 'SmallGigCardCarousel',
                ...defaultOptions,
                inputs: gigListingsInputs,
                noWrap: false,
            },
        },
        {
            component: ExpertCardCarousel,
            options: {
                name: 'ExpertCardCarousel',
                ...defaultOptions,
                inputs: [
                    ...expertListingsInputs,
                    {
                        name: 'slidesToShow',
                        type: 'number',
                        defaultValue: 4,
                    },
                    {
                        name: 'disableFiverrChoice',
                        type: 'boolean',
                        defaultValue: false,
                        showIf: `options.get('engine') === 'search' || options.get('engine') === 'categories'`,
                    },
                    {
                        name: 'sellerIdsOrder',
                        type: 'text',
                        helperText: 'String of seller ids separated by comma. Example: 123,456,789',
                        showIf: `options.get('engine') === 'search' || options.get('engine') === 'categories'`,
                    },
                ],
            },
            noWrap: false,
            hasChildren: true,
        },
        {
            component: ExpertCardListings,
            options: {
                name: 'ExpertCardListings',
                ...defaultOptions,
                inputs: [
                    ...expertListingsInputs,
                    {
                        name: 'layout',
                        type: 'enum',
                        enum: Object.values(LISTINGS_LAYOUT),
                        defaultValue: LISTINGS_LAYOUT.LIST,
                    },
                    {
                        name: 'forceSmallCard',
                        type: 'boolean',
                        defaultValue: false,
                    },
                ],
                noWrap: false,
            },
        },
    ],
};

export default LISTINGS_COMPONENTS_CONFIG;
