const BUILDER_COMPONENTS_MENU = {
    name: 'Builder Components',
    items: [
        { name: 'Image' },
        { name: 'Custom Code' },
        { name: 'Symbol' },
        { name: 'Slot' },
        { name: 'Builder:Carousel' },
    ],
};

export default BUILDER_COMPONENTS_MENU;
