import {
    MIXPANEL_CLICK_EVENT_NAME,
    MIXPANEL_COMPONENT_NAME,
} from '../../../../../page/components/EventReportingManager/constants';

const getEventContext = ({ componentName, eventEnrichment, eventName }) => ({
    bq: {
        'page.element.type': componentName,
        ...eventEnrichment,
    },
    mixpanel: {
        [MIXPANEL_COMPONENT_NAME]: componentName,
        [MIXPANEL_CLICK_EVENT_NAME]: eventName,
        ...eventEnrichment,
    },
});

export default getEventContext;
