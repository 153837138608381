export const editorCss = `
    a a {
        border: 2px dashed red !important;
        position: relative !important;
    }

    a a:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "Link inside Link detected";
        width: 100%;
        height: 100%;
        color: black;
        z-index: 1;
        text-align: center;
        transform: translateY(50%);
        font-weight: bold;
        text-shadow: 0 0 5px red;
    }

    a a:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: red;
        content: "";
        width: 100%;
        height: 100%;
        opacity: 0.3;
        color: black;
    }
`;
