import { CUSTOM_COMPONENTS_CATEGORY } from '../../../constants';
import ICONS_COMPONENTS_CONFIG from './icons';
import CLOUDINARY_IMAGE_CONFIG from './cloudinaryImage';
import RECOMMENDATIONS_INSPIRE_CAROUSEL_CONFIG from './recommendationsInspireCarousel';
import SEARCH_BAR_CONFIG from './searchBar';
import LISTINGS_COMPONENTS_CONFIG from './listings';
import BUILDER_COMPONENTS_CONFIG from './builder';
import { MODAL_ADAPTER_CONFIG } from './modalAdapter';
import { TABLE_OF_CONTENTS_CONFIG } from './tableOfContents';
import SOCIAL_SHARING_CONFIG from './socialSharing';

const MANUAL_REGISTRATION_COMPONENTS_LIST = {
    Icons: ICONS_COMPONENTS_CONFIG,
    CustomComponents: {
        components: [
            ...CLOUDINARY_IMAGE_CONFIG.components,
            ...SEARCH_BAR_CONFIG.components,
            ...RECOMMENDATIONS_INSPIRE_CAROUSEL_CONFIG.components,
            ...LISTINGS_COMPONENTS_CONFIG.components,
            ...MODAL_ADAPTER_CONFIG.components,
            ...TABLE_OF_CONTENTS_CONFIG.components,
            ...SOCIAL_SHARING_CONFIG.components,
        ],
        category: CUSTOM_COMPONENTS_CATEGORY,
    },
    BuilderComponents: BUILDER_COMPONENTS_CONFIG,
};

export default MANUAL_REGISTRATION_COMPONENTS_LIST;
