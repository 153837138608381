import { merge, capitalize, set } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { bigQuery, mixpanel } from '@fiverr-private/obs';
import { injectParamsToField } from '../../utils/injectParamsToField';

const USER_ACTIONS = 'user_actions';
const DEFAULT_PLATFORM = 'web';
const CLICK_EVENT_NAME = 'Builder LP - Component Click';

export const convertContextToMixpanel = (context = {}) =>
    Object.entries(context).reduce((result, [key, value]) => {
        const fieldName = key.split('.').map(capitalize).join(' ');
        result[fieldName] = injectParamsToField(value);

        return result;
    }, {});

export const convertContextToBQ = (context = {}) =>
    Object.entries(context).reduce((result, [key, value]) => {
        set(result, key, injectParamsToField(value));
        return result;
    }, {});

export const sendMixpanelEvent = (eventName, managerContext, mixpanelEventContext = {}) => {
    mixpanel.track(eventName, {
        'Page Name': managerContext?.page?.name,
        'Page Owner': managerContext?.publisher?.subDomain,
        ...mixpanelEventContext,
    });
};

export const sendEvent = (eventName, type, eventContext, managerContext) => {
    const { userId, pageCtxId, userGuid } = getContext();
    const bqEventContext = convertContextToBQ(eventContext.bq);
    const injectedEventName = injectParamsToField(eventName);
    const mixpanelEventContext = convertContextToMixpanel(eventContext.mixpanel);
    const eventData = merge(
        {
            group: USER_ACTIONS,
            action: {
                type,
            },
            type: injectedEventName,
            user: {
                id: userId,
            },
            uid: userGuid,
            page: {
                ctx_id: pageCtxId,
            },
            platform: DEFAULT_PLATFORM,
        },
        managerContext,
        bqEventContext
    );
    bigQuery.send(eventData);
    sendMixpanelEvent(CLICK_EVENT_NAME, managerContext, mixpanelEventContext);
};
