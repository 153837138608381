import { loadable } from '@fiverr-private/perseus';
import ModalConfig from '../../../../../../docs/builderOverlayComponents/Modal.json';
import ModalContentConfig from '../../../../../../docs/builderOverlayComponents/ModalContent.json';
import ModalTriggerConfig from '../../../../../../docs/builderOverlayComponents/ModalTrigger.json';
import ModalHeaderConfig from '../../../../../../docs/builderOverlayComponents/ModalHeader.json';
import ModalBodyConfig from '../../../../../../docs/builderOverlayComponents/ModalBody.json';
import ModalFooterConfig from '../../../../../../docs/builderOverlayComponents/ModalFooter.json';
import ModalLoaderConfig from '../../../../../../docs/builderOverlayComponents/ModalLoader.json';
import DrawerConfig from '../../../../../../docs/builderOverlayComponents/Drawer.json';
import DrawerContentConfig from '../../../../../../docs/builderOverlayComponents/DrawerContent.json';
import DrawerTriggerConfig from '../../../../../../docs/builderOverlayComponents/DrawerTrigger.json';
import DrawerHeaderConfig from '../../../../../../docs/builderOverlayComponents/DrawerHeader.json';
import DrawerBodyConfig from '../../../../../../docs/builderOverlayComponents/DrawerBody.json';
import ModalCancelBtnConfig from '../../../../../../docs/builderOverlayComponents/ModalCancelBtn.json';
import DrawerFooterConfig from '../../../../../../docs/builderOverlayComponents/DrawerFooter.json';
import { VERSIONS } from '../../../../constants';
import {
    BUILDER_COMPONENT_TYPE,
    BUILDER_ELEMENT,
    DEFAULT_TEXT_COMPONENT,
} from '../../../../../../bin/generate_docs/generate_builder_components/config';
import { withCloseButtonInput } from '../helpers/inputs';

const Modal = loadable(() => import(/* webpackChunkName: "ModalV2" */ './modal'));
Modal.displayName = 'Modal';

const ModalContent = loadable(() => import(/* webpackChunkName: "ModalV2" */ './modalContent'));
ModalContent.displayName = 'ModalContent';

const ModalTrigger = loadable(() => import(/* webpackChunkName: "ModalV2" */ './modalTrigger'));
ModalTrigger.displayName = 'ModalTrigger';

const ModalHeader = loadable(() => import(/* webpackChunkName: "ModalV2" */ './modalHeader'));
ModalHeader.displayName = 'ModalHeader';

const ModalBody = loadable(() => import(/* webpackChunkName: "ModalV2" */ './modalBody'));
ModalBody.displayName = 'ModalBody';

const ModalFooter = loadable(() => import(/* webpackChunkName: "ModalV2" */ './modalFooter'));
ModalFooter.displayName = 'ModalFooter';

const ModalCancelBtn = loadable(() => import(/* webpackChunkName: "ModalV2" */ './modalCancelBtn'));
ModalCancelBtn.displayName = 'ModalCancelBtn';

const ModalLoader = loadable(() => import(/* webpackChunkName: "ModalV2" */ './modalLoader'));
ModalLoader.displayName = 'ModalLoader';

const Drawer = loadable(() => import(/* webpackChunkName: "Drawer" */ './drawer'));
Drawer.displayName = 'Drawer';

const DrawerContent = loadable(() => import(/* webpackChunkName: "Drawer" */ './drawerContent'));
DrawerContent.displayName = 'DrawerContent';

const DrawerTrigger = loadable(() => import(/* webpackChunkName: "Drawer" */ './drawerTrigger'));
DrawerTrigger.displayName = 'DrawerTrigger';

const DrawerHeader = loadable(() => import(/* webpackChunkName: "Drawer" */ './drawerHeader'));
DrawerHeader.displayName = 'DrawerHeader';

const DrawerBody = loadable(() => import(/* webpackChunkName: "Drawer" */ './drawerBody'));
DrawerBody.displayName = 'DrawerBody';

const DrawerFooter = loadable(() => import(/* webpackChunkName: "Drawer" */ './drawerFooter'));
DrawerFooter.displayName = 'DrawerFooter';

const OVERLAY_COMPONENTS_CONFIG = {
    components: [
        {
            element: Modal,
            componentConfig: ModalConfig,
            version: VERSIONS.V2,
            manualOptions: {
                defaultChildren: [
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'v2-fiverr:Button',
                                },
                                children: [
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: DEFAULT_TEXT_COMPONENT,
                                    },
                                ],
                            },
                        ],
                        component: {
                            name: 'fiverr:ModalTrigger',
                        },
                    },
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'fiverr:ModalHeader',
                                    options: {
                                        withCloseButton: true,
                                    },
                                },
                                children: [
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: {
                                            name: 'fiverr:Heading',
                                            options: {
                                                text: 'Modal title!',
                                                as: 'h3',
                                            },
                                        },
                                    },
                                ],
                            },
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'fiverr:ModalBody',
                                },
                                children: [
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: DEFAULT_TEXT_COMPONENT,
                                    },
                                ],
                            },
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'fiverr:ModalFooter',
                                },
                                children: [
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: {
                                            name: 'v2-fiverr:Button',
                                        },
                                        children: [
                                            {
                                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                                component: DEFAULT_TEXT_COMPONENT,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                        component: {
                            name: 'fiverr:ModalContent',
                        },
                    },
                ],
            },
        },
        {
            element: ModalContent,
            componentConfig: ModalContentConfig,
        },
        {
            element: ModalTrigger,
            componentConfig: ModalTriggerConfig,
        },
        {
            element: ModalHeader,
            componentConfig: ModalHeaderConfig,
            manualInputs: [withCloseButtonInput],
        },
        {
            element: ModalBody,
            componentConfig: ModalBodyConfig,
        },
        {
            element: ModalFooter,
            componentConfig: ModalFooterConfig,
        },
        {
            element: ModalCancelBtn,
            componentConfig: ModalCancelBtnConfig,
        },
        {
            element: ModalLoader,
            componentConfig: ModalLoaderConfig,
        },
        {
            element: Drawer,
            componentConfig: DrawerConfig,
            manualOptions: {
                defaultChildren: [
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'v2-fiverr:Button',
                                },
                                children: [
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: DEFAULT_TEXT_COMPONENT,
                                    },
                                ],
                            },
                        ],
                        component: {
                            name: 'fiverr:DrawerTrigger',
                        },
                    },
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'fiverr:DrawerHeader',
                                    options: {
                                        withCloseButton: true,
                                    },
                                },
                                children: [
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: {
                                            name: 'fiverr:Heading',
                                            options: {
                                                text: 'Drawer title!',
                                                as: 'h3',
                                            },
                                        },
                                    },
                                ],
                            },
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'fiverr:DrawerBody',
                                },
                                children: [
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: DEFAULT_TEXT_COMPONENT,
                                    },
                                ],
                            },
                            {
                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                component: {
                                    name: 'fiverr:DrawerFooter',
                                },
                                children: [
                                    {
                                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                        component: {
                                            name: 'v2-fiverr:Button',
                                        },
                                        children: [
                                            {
                                                [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                                component: DEFAULT_TEXT_COMPONENT,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                        component: {
                            name: 'fiverr:DrawerContent',
                        },
                    },
                ],
            },
        },
        {
            element: DrawerContent,
            componentConfig: DrawerContentConfig,
        },
        {
            element: DrawerTrigger,
            componentConfig: DrawerTriggerConfig,
        },
        {
            element: DrawerHeader,
            componentConfig: DrawerHeaderConfig,
            manualInputs: [withCloseButtonInput],
        },
        {
            element: DrawerBody,
            componentConfig: DrawerBodyConfig,
        },
        {
            element: DrawerFooter,
            componentConfig: DrawerFooterConfig,
        },
    ],
    category: 'Overlay',
};

export default OVERLAY_COMPONENTS_CONFIG;
