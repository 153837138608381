import { loadable } from '@fiverr-private/perseus';
import CarouselConfig from '../../../../../../docs/builderCarouselComponents/Carousel.json';
import ArrowsConfig from '../../../../../../docs/builderCarouselComponents/Arrows.json';
import SlideConfig from '../../../../../../docs/builderCarouselComponents/Slide.json';
import TrackConfig from '../../../../../../docs/builderCarouselComponents/Track.json';
import {
    BUILDER_COMPONENT_TYPE,
    BUILDER_ELEMENT,
} from '../../../../../../bin/generate_docs/generate_builder_components/config';
import { COMPONENT_PREFIX } from '../../../../constants';
import {
    clickEventNameInput,
    componentNameInput,
    eventEnrichmentInput,
    withSlidesIndicatorInput,
} from '../helpers/inputs';

const Carousel = loadable(() => import(/* webpackChunkName: "Carousel" */ './carousel'));
Carousel.displayName = 'Carousel';

const Arrows = loadable(() => import(/* webpackChunkName: "Carousel" */ './arrows'));
Arrows.displayName = 'Arrows';

const Slide = loadable(() => import(/* webpackChunkName: "Carousel" */ './slide'));
Slide.displayName = 'Slide';

const Track = loadable(() => import(/* webpackChunkName: "Carousel" */ './track'));
Track.displayName = 'Track';

const CAROUSEL_COMPONENTS_CONFIG = {
    components: [
        {
            element: Carousel,
            componentConfig: CarouselConfig,
            manualInputs: [withSlidesIndicatorInput],
            manualOptions: {
                noWrap: false,
                defaultChildren: [
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        children: [1, 2, 3, 4, 5].map((idx) => ({
                            [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                            component: {
                                name: 'fiverr:Slide',
                            },
                            children: [
                                {
                                    [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                    component: {
                                        name: `${COMPONENT_PREFIX}Center`,
                                        options: {
                                            height: { default: '100px' },
                                            backgroundColor: { default: 'green_300' },
                                        },
                                    },
                                    children: [
                                        {
                                            [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                                            component: {
                                                name: `${COMPONENT_PREFIX}Text`,
                                                options: { text: `Slide ${idx}`, embed: true },
                                            },
                                        },
                                    ],
                                },
                            ],
                        })),
                        component: {
                            name: 'fiverr:Track',
                        },
                    },
                    {
                        [BUILDER_COMPONENT_TYPE]: BUILDER_ELEMENT,
                        component: {
                            name: 'fiverr:Arrows',
                        },
                    },
                ],
            },
        },
        {
            element: Arrows,
            componentConfig: ArrowsConfig,
            manualInputs: [clickEventNameInput, componentNameInput, eventEnrichmentInput],
        },
        {
            element: Slide,
            componentConfig: SlideConfig,
        },
        {
            element: Track,
            componentConfig: TrackConfig,
        },
    ],
    category: 'Carousel',
};

export default CAROUSEL_COMPONENTS_CONFIG;
