import { carouselConfig } from '@builder.io/widgets/dist/lib/components/Carousel.config';
import { accordionConfig } from '@builder.io/widgets/dist/lib/components/Accordion.config';
import { loadable } from '@fiverr-private/perseus';

const CarouselComponent = loadable(() => import(/* webpackChunkName: "CarouselComponent" */ './carouselComponent'));

const AccordionComponent = loadable(() => import(/* webpackChunkName: "AccordionComponent" */ './accordionComponent'));

const BUILDER_COMPONENTS_CONFIG = {
    components: [
        {
            component: CarouselComponent,
            options: { ...carouselConfig, noWrap: false, hideFromInsertMenu: true },
        },
        {
            component: AccordionComponent,
            options: {
                ...accordionConfig,
                hideFromInsertMenu: true,
                noWrap: false,
            },
        },
    ],
    isBuilderComponents: true,
};

export default BUILDER_COMPONENTS_CONFIG;
