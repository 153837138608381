import { loadable } from '@fiverr-private/perseus';

const TableOfContents = loadable(() => import(/* webpackChunkName: "TableOfContents" */ './TableOfContents'));

export const TABLE_OF_CONTENTS_CONFIG = {
    components: [
        {
            component: TableOfContents,
            options: {
                name: 'TableOfContents',
                inputs: [
                    {
                        name: 'isNumbered',
                        type: 'boolean',
                        required: false,
                        defaultValue: false,
                    },
                    {
                        name: 'h2',
                        type: 'boolean',
                        required: false,
                        defaultValue: false,
                    },
                    {
                        name: 'h3',
                        type: 'boolean',
                        required: false,
                        defaultValue: true,
                    },
                ],
            },
            noWrap: false,
            hasChildren: false,
        },
    ],
};
