import { loadable } from '@fiverr-private/perseus';
import { theme } from '@fiverr-private/theme';
import {
    FLAG_ICON_SIZES,
    L1_ICON_SIZES,
    L2_ICON_SIZES,
    SYSTEM_ICON_SIZES,
} from '@fiverr-private/visuals/dist/esm/src/components/constants';
import { COMPONENT_PREFIX } from '../../../../constants';

const helperTextVisuals =
    'See available assets on https://stories.fiverr-gw.com/penta_storybook/?path=/docs/visuals-docs--docs';

const ArrowRightIcon = loadable(() => import(/* webpackChunkName: "ArrowRightIcon" */ './arrowRightIcon'));

const CloseIcon = loadable(() => import(/* webpackChunkName: "CloseIcon" */ './closeIcon'));

const EnvelopeIcon = loadable(() => import(/* webpackChunkName: "EnvelopeIcon" */ './envelopeIcon'));

const FacebookIcon = loadable(() => import(/* webpackChunkName: "FacebookIcon" */ './facebookIcon'));

const LinkedinIcon = loadable(() => import(/* webpackChunkName: "LinkedinIcon" */ './linkedinIcon'));

const TwitterIcon = loadable(() => import(/* webpackChunkName: "TwitterIcon" */ './twitterIcon'));

const WhatsappIcon = loadable(() => import(/* webpackChunkName: "WhatsappIcon" */ './whatsappIcon'));

const SystemIcon = loadable(() => import(/* webpackChunkName: "SystemIcon" */ './systemIcon'));

const L1Icon = loadable(() => import(/* webpackChunkName: "L1Icon" */ './l1Icon'));

const L2Icon = loadable(() => import(/* webpackChunkName: "L2Icon" */ './l2Icon'));

const Logo = loadable(() => import(/* webpackChunkName: "Logo" */ './logo'));

const Flag = loadable(() => import(/* webpackChunkName: "Flag" */ './flag'));

const ICON_INPUTS = [
    {
        name: 'size',
        type: 'enum',
        required: false,
        defaultValue: '16',
        enum: ['12', '16', '20', '24'],
    },
    {
        name: 'color',
        type: 'color',
        required: false,
    },
];

const ICONS_COMPONENTS_CONFIG = {
    components: [
        {
            component: SystemIcon,
            options: {
                name: `${COMPONENT_PREFIX}SystemIcon`,
                inputs: [
                    {
                        name: 'iconName',
                        type: 'string',
                        required: false,
                        defaultValue: 'HeartIcon',
                        helperText: helperTextVisuals,
                    },
                    {
                        name: 'size',
                        type: 'enum',
                        required: false,
                        defaultValue: 'sm',
                        enum: Object.keys(SYSTEM_ICON_SIZES),
                    },
                    {
                        name: 'color',
                        type: 'enum',
                        required: false,
                        defaultValue: 'grey_900',
                        enum: Object.keys(theme.colors),
                    },
                ],
            },
        },
        {
            component: L1Icon,
            options: {
                name: `${COMPONENT_PREFIX}L1Icon`,
                inputs: [
                    {
                        name: 'iconName',
                        type: 'string',
                        required: true,
                        defaultValue: 'CompassL1Icon',
                        helperText: helperTextVisuals,
                    },
                    {
                        name: 'size',
                        type: 'enum',
                        required: false,
                        defaultValue: 'sm',
                        enum: Object.keys(L1_ICON_SIZES),
                    },
                    {
                        name: 'color',
                        type: 'enum',
                        required: false,
                        defaultValue: 'grey_1200',
                        enum: Object.keys(theme.colors),
                    },
                ],
            },
        },
        {
            component: L2Icon,
            options: {
                name: `${COMPONENT_PREFIX}L2Icon`,
                inputs: [
                    {
                        name: 'iconName',
                        type: 'string',
                        required: true,
                        defaultValue: 'PaymentBalanceL2Icon',
                        helperText: helperTextVisuals,
                    },
                    {
                        name: 'size',
                        type: 'enum',
                        required: false,
                        defaultValue: 'sm',
                        enum: Object.keys(L2_ICON_SIZES),
                    },
                ],
            },
        },
        {
            component: Flag,
            options: {
                name: `${COMPONENT_PREFIX}Flag`,
                inputs: [
                    {
                        name: 'flagName',
                        type: 'string',
                        required: false,
                        defaultValue: 'IlFlag',
                        helperText: helperTextVisuals,
                    },
                    {
                        name: 'size',
                        type: 'enum',
                        required: false,
                        defaultValue: 'lg',
                        enum: Object.keys(FLAG_ICON_SIZES),
                    },
                ],
            },
        },
        {
            component: Logo,
            options: {
                name: `${COMPONENT_PREFIX}Logo`,
                inputs: [
                    {
                        name: 'logoName',
                        type: 'string',
                        required: false,
                        defaultValue: 'FiverrLogo',
                        helperText: helperTextVisuals,
                    },
                ],
            },
        },
        {
            component: ArrowRightIcon,
            options: {
                name: `${COMPONENT_PREFIX}Arrow right icon`,
                inputs: ICON_INPUTS,
                hideFromInsertMenu: true,
            },
        },
        {
            component: CloseIcon,
            options: {
                name: `${COMPONENT_PREFIX}Close icon`,
                inputs: ICON_INPUTS,
                hideFromInsertMenu: true,
            },
        },
        {
            component: EnvelopeIcon,
            options: {
                name: `${COMPONENT_PREFIX}Envelope icon`,
                inputs: ICON_INPUTS,
                hideFromInsertMenu: true,
            },
        },
        {
            component: FacebookIcon,
            options: {
                name: `${COMPONENT_PREFIX}Facebook icon`,
                inputs: ICON_INPUTS,
                hideFromInsertMenu: true,
            },
        },
        {
            component: TwitterIcon,
            options: {
                name: `${COMPONENT_PREFIX}Twitter icon`,
                inputs: ICON_INPUTS,
                hideFromInsertMenu: true,
            },
        },
        {
            component: LinkedinIcon,
            options: {
                name: `${COMPONENT_PREFIX}Linkedin icon`,
                inputs: ICON_INPUTS,
                hideFromInsertMenu: true,
            },
        },
        {
            component: WhatsappIcon,
            options: {
                name: `${COMPONENT_PREFIX}Whatsapp icon`,
                inputs: ICON_INPUTS,
                hideFromInsertMenu: true,
            },
        },
    ],
    category: 'Visuals',
};

export default ICONS_COMPONENTS_CONFIG;
