import { loadable } from '@fiverr-private/perseus';
import { COMPONENT_PREFIX } from '../../../constants';

const SearchBar = loadable(() => import(/* webpackChunkName: "SearchBar" */ '@fiverr-private/search_bar'));

const SEARCH_BAR_CONFIG = {
    components: [
        {
            component: SearchBar,
            options: {
                name: `${COMPONENT_PREFIX}Search Bar`,
                inputs: [
                    {
                        name: 'theme',
                        type: 'enum',
                        defaultValue: 'default',
                        required: false,
                        enum: ['default', 'dark', 'dark_green'],
                    },
                    { name: 'placeholder', type: 'string', required: false },
                    { name: 'source', type: 'string', required: false },
                    {
                        name: 'submitOnSuggestionSelected',
                        type: 'boolean',
                        defaultValue: false,
                        required: false,
                    },
                    {
                        name: 'submitOnEnter',
                        type: 'boolean',
                        defaultValue: false,
                        required: false,
                    },
                    {
                        name: 'dimBackgroundEnabled',
                        type: 'boolean',
                        defaultValue: false,
                        required: false,
                    },
                    {
                        name: 'submitButtonType',
                        type: 'enum',
                        defaultValue: 'icon',
                        required: false,
                        enum: ['icon', 'text', 'button_inside'],
                    },
                ],
                noWrap: false,
                hasChildren: true,
            },
        },
    ],
};

export default SEARCH_BAR_CONFIG;
