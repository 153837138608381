import { useEffect } from 'react';
import { currencyFormat, numberFormat as numberFormatLocalization } from '@fiverr-private/localization';
import { CurrencyConverter } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { ATTEMPT_QUERY_PARAM } from '../../../server/constants';

export const numberFormat = (value, options = {}) =>
    numberFormatLocalization(value, { fallbackValue: value, ...options });

export const getConvertPriceFromUSD =
    ({ currencyObject = {} } = {}) =>
    (amount, forceRound = true, asNumber = false) => {
        const currency = { ...currencyObject, forceRound, forceRoundFromAmount: 0 };
        const currencyConverter = new CurrencyConverter(currency);
        const convertedAmount = amount * currency.rate;
        const fallbackValue = currencyConverter.convert(amount, { commaSeparate: true });

        return currencyFormat(convertedAmount, currency.name, {
            fallbackValue,
            noFractionDigits: currency.forceRound,
            asNumber,
        });
    };

export const useBuilderTimeoutRetry = (isBuilderTimeoutError) => {
    useEffect(() => {
        const { queryParameters = {} } = getContext();

        const url = new URL(window.location);
        const { searchParams } = url;

        if (isBuilderTimeoutError) {
            const attemptNumber = Number(queryParameters[ATTEMPT_QUERY_PARAM]) || 1;

            setTimeout(() => {
                searchParams.set(ATTEMPT_QUERY_PARAM, (attemptNumber + 1).toString());
                window.location.search = decodeURIComponent(searchParams.toString());
            }, attemptNumber * 1000);
        } else if (searchParams.has(ATTEMPT_QUERY_PARAM)) {
            searchParams.delete(ATTEMPT_QUERY_PARAM);
            history.pushState({}, '', decodeURIComponent(url.toString()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
