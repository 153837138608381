import { get } from 'lodash';
import { theme } from '@fiverr-private/theme';
import {
    ENUM,
    OBJECT,
    STRING,
    OBJECT_FIELDS_VARIANTS,
    OBJECT_KEYS,
    OBJECT_VALUES,
    NUMBER,
    BOOLEAN,
} from '../../../constants';
import BoxConfig from '../../../../../docs/builderThemeComponents/Box.json';

const SIMPLE_TYPES = [NUMBER, STRING, BOOLEAN];

const getReferenceValue = (config) => {
    let res = [];
    const references = Array.isArray(config.reference) ? config.reference : [config.reference];
    references.forEach((reference) => {
        const value = get(theme, reference);

        if (config.method === OBJECT_KEYS) {
            res = res.concat(Object.keys(value));
        }
        if (config.method === OBJECT_VALUES) {
            res = res.concat(Object.values(value));
        }
    });

    return res;
};

const getEnumSubFields = (value, required, variant) =>
    OBJECT_FIELDS_VARIANTS[variant].map((field) => ({
        name: field,
        type: ENUM,
        required: field === 'default' ? required : false,
        enum: value,
    }));

const getSubFieldsByType = (required, variant, type) =>
    OBJECT_FIELDS_VARIANTS[variant].map((field) => ({
        name: field,
        type,
        required: field === 'default' ? required : false,
    }));

const isObject = (value) => !Array.isArray(value) && typeof value === OBJECT;

const hasEnumReference = (input) => input.type === ENUM && isObject(input.enum);

const hasObjectReference = (input) => input.type === OBJECT && isObject(input.subFields);

const hasNestedObjectReference = (input) =>
    input.type === OBJECT &&
    Array.isArray(input.subFields) &&
    input.subFields.find((item) => hasEnumReference(item) || hasObjectReference(item));

const injectReference = (input) => {
    if (hasObjectReference(input) && SIMPLE_TYPES.includes(input.subFields.reference)) {
        input.subFields = getSubFieldsByType(input.required, input.subFields.variant, input.subFields.reference);
    } else if (hasObjectReference(input)) {
        if (input.subFields.enum) {
            input.subFields = getEnumSubFields(input.subFields.enum, input.required, input.subFields.variant);
        } else {
            input.subFields = getEnumSubFields(
                getReferenceValue(input.subFields),
                input.required,
                input.subFields.variant
            );
        }
    }
    if (hasEnumReference(input)) {
        input.enum = getReferenceValue(input.enum);
    }
    if (hasNestedObjectReference(input)) {
        input.subFields.forEach(injectReference);
    }
};

const calculateProps = (inputs) => {
    const inputsRes = inputs.map((input) =>
        typeof input === 'string' ? { ...BoxConfig.options.inputs[input] } : input
    );
    inputsRes.forEach(injectReference);
    return inputsRes;
};

export default calculateProps;
