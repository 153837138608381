/* eslint-disable no-console */
import { logger } from '@fiverr-private/obs';

export const unInitializedErrorString = 'Please initialize logger before calling';

class AppLogger {
    constructor(logContext = {}) {
        this.context = logContext;
    }

    contextualise(data = {}) {
        return { ...data, ...this.context };
    }

    error(error, data = {}) {
        logger.error(error, this.contextualise(data));
    }

    warn(message, data = {}) {
        logger.warn(message, this.contextualise(data));
    }
}

const Logger = (() => {
    let instance = {
        error: () => console.error(`${unInitializedErrorString} logger.error`),
        warn: () => console.error(`${unInitializedErrorString} logger.warn`),
    };

    const init = (logContext) => {
        instance = new AppLogger(logContext);
        return instance;
    };

    return {
        init,
        getInstance: () => instance,
    };
})();

export default Logger;
