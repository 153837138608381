import React, { useContext } from 'react';
import { node, object } from 'prop-types';
import { merge } from 'lodash';
import { useImpressionContainer, useImpressionItem } from '@fiverr-private/impressionable';
import { EventReportingManagerContext } from '../EventReportingManager';

const ImpressionEventContainer = ({ children, context = {} }) => {
    const { context: managerContext } = useContext(EventReportingManagerContext);
    const [ref, containerId] = useImpressionContainer();
    useImpressionItem({
        selfRef: ref,
        selfId: containerId,
        enrichment: merge(managerContext, context),
    });

    return <div ref={ref}>{children}</div>;
};

ImpressionEventContainer.propTypes = {
    children: node.isRequired,
    context: object,
};

export default ImpressionEventContainer;
